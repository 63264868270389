<template>
	<div class="opacity-page">
		<div class="header-filter" v-if="loaded">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('salary.contracts.search-by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
					</div>
					<div class="actions-page mobile">
						<button class="btn-tbf blue" @click="$router.push({name: 'contract-create'})">
							<div class="icon">
								<icon-plus />
							</div>
							<span v-if="$resize && $mq.above(600)" class="text">{{ $t('salary.contracts.new-contract') }}</span>
						</button>
						<button class="btn-tbf white-blue mr-15" @click="$router.push({name: 'contract-co-last-years'})">
							<!-- <div class="icon">
								<icon-plus />
							</div> -->
							<span v-if="$resize && $mq.above(600)" class="text">{{ $t('salary.contracts.co_last_years') }}</span>
							<span v-else class="text">{{ $t('salary.contracts.co_last_short') }}</span>
						</button>
						<div style="margin-right: 15px;" class="download-button dropdown" ref="dropdownUser" id="dropdownUser" data-toggle="dropdown">
							<div class="icon settings"><icon-download /></div>

							<div class="dropdown-menu dropdown-menu-right" id="dropdownApplications" aria-labelledby="dropdownApplications">
								<div class="title-group">{{ $t('salary.download.contract_report') }}</div>
								<div @click="donwloadContractRaportPdf(null)" class="dropdown-item">
									<div class="icon"><icon-pdf /></div>
									<div class="text">{{ $t('salary.download.list_contract_pdf') }}</div>
								</div>
								<div @click="donwloadContractRaportXls(null)" class="dropdown-item last">
									<div class="icon"><icon-xls /></div>
									<div class="text">{{ $t('salary.download.list_contract_xls') }}</div>
								</div>
								<div class="title-group">{{ $t('salary.download.contract_report_active') }}</div>
								<div @click="donwloadContractRaportPdf(true)" class="dropdown-item">
									<div class="icon"><icon-pdf /></div>
									<div class="text">{{ $t('salary.download.list_contract_pdf') }}</div>
								</div>
								<div @click="donwloadContractRaportXls(true)" class="dropdown-item last">
									<div class="icon"><icon-xls /></div>
									<div class="text">{{ $t('salary.download.list_contract_xls') }}</div>
								</div>
								<div class="title-group">{{ $t('salary.download.contract_report_inactive') }}</div>
								<div @click="donwloadContractRaportPdf(false)" class="dropdown-item">
									<div class="icon"><icon-pdf /></div>
									<div class="text">{{ $t('salary.download.list_contract_pdf') }}</div>
								</div>
								<div @click="donwloadContractRaportXls(false)" class="dropdown-item last">
									<div class="icon"><icon-xls /></div>
									<div class="text">{{ $t('salary.download.list_contract_xls') }}</div>
								</div>
								<div class="title-group">{{ $t('salary.download.insurers') }}</div>
								<div @click="donwloadInsurersPdf(false)" class="dropdown-item">
									<div class="icon"><icon-pdf /></div>
									<div class="text">{{ $t('salary.download.list_insurers_pdf') }}</div>
								</div>
								<div @click="donwloadInsurersXls(false)" class="dropdown-item last">
									<div class="icon"><icon-xls /></div>
									<div class="text">{{ $t('salary.download.list_insurers_xls') }}</div>
								</div>
								<div class="title-group">{{ $t('salary.download.dependents') }}</div>
								<div @click="donwloadInsurersPdf(true)" class="dropdown-item">
									<div class="icon"><icon-pdf /></div>
									<div class="text">{{ $t('salary.download.list_dependents_pdf') }}</div>
								</div>
								<div @click="donwloadInsurersXls(true)" class="dropdown-item">
									<div class="icon"><icon-xls /></div>
									<div class="text">{{ $t('salary.download.list_dependents_xls') }}</div>
								</div>
							</div>
						</div>
						<div class="actions actions-dropdown">
							<div class="actions-page mobile">
								<button v-if="$resize && $mq.above(600)" class="btn-tbf grey filter-btn" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
									<div class="counter" v-bind:class="{active: queryFiltersCount > 0}">{{ queryFiltersCount }}</div>
									<span class="text">{{ $t('general.filters') }}</span>
									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<button v-else-if="$resize && $mq.below(599)" class="btn-tbf grey filter-btn-mobile" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
									<div class="icon settings"><icon-settings /></div>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
			<div class="row-space-tbf filters-extend" :class="[{mobile: $resize && $mq.below(1120)}]" v-if="extendFilters">
				<div class="space-left"></div>
				<div class="content" :class="[{'contract-25': $resize && $mq.above(1121)}]">
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedUsers.length">{{ $t('filters.by_users') }}</span>
							<span class="text" v-else-if="selectedUsers.length == 1">{{ filterUsers.find(el => el.id == selectedUsers[0]).name }}</span>
							<span class="text" v-else>{{ selectedUsers.length }} {{ $t('filters.responsibles_selected') }}</span>

							<div class="icon-filter" v-if="!selectedUsers.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedUsers')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchUsers" :placeholder="$t('filters.search_users')">
							</div>

							<template v-for="user in filteredUsers">
								<div class="dropdown-item"  @click.stop="selectOptionFilter('selectedUsers', user.id)" v-bind:class="{active: selectedUsers.includes(user.id)}" :key="'filter-user-' + user.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ user.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedDepartments.length">{{ $t('filters.by_departments') }}</span>
							<span class="text" v-else-if="selectedDepartments.length == 1">{{ filterDepartments.find(el => el.id == selectedDepartments[0]).name }}</span>
							<span class="text" v-else>{{ selectedDepartments.length }} {{ $t('filters.departments_selected') }}</span>

							<div class="icon-filter" v-if="!selectedDepartments.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedDepartments')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchDepartments" :placeholder="$t('filters.search_department')">
							</div>

							<template v-for="department in filteredDepartments">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedDepartments', department.id)" v-bind:class="{active: selectedDepartments.includes(department.id)}" :key="'filter-department-' + department.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ department.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown last-but-one">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedWorkstations.length">{{ $t('filters.by_workstations') }}</span>
							<span class="text" v-else-if="selectedWorkstations.length == 1">{{ filterWorkstations.find(el => el.id == selectedWorkstations[0]).name }}</span>
							<span class="text" v-else>{{ selectedWorkstations.length }} {{ $t('filters.workstations_selected') }}</span>

							<div class="icon-filter" v-if="!selectedWorkstations.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedWorkstations')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchWorkstations" :placeholder="$t('filters.search_workstations')">
							</div>

							<template v-for="workstation in filteredWorkstations">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedWorkstations', workstation.id)" v-bind:class="{active: selectedWorkstations.includes(workstation.id)}" :key="'filter-workstation-' + workstation.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ workstation.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown last">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedRoles.length">{{ $t('filters.by_roles') }}</span>
							<span class="text" v-else-if="selectedRoles.length == 1">{{ filterRoles.find(el => el.id == selectedRoles[0]).name }}</span>
							<span class="text" v-else>{{ selectedRoles.length }} {{ $t('filters.roles_selected') }}</span>

							<div class="icon-filter" v-if="!selectedRoles.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedRoles')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchRoles" :placeholder="$t('filters.search_roles')">
							</div>

							<template v-for="role in filteredRoles">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedRoles', role.id)" v-bind:class="{active: selectedRoles.includes(role.id)}" :key="'filter-role-' + role.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ role.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedContractType.length">{{ $t('filters.by_contract_type') }}</span>
							<span class="text" v-else-if="selectedContractType.length == 1">{{ filterContractsType.find(el => el.id == selectedContractType[0]).name }}</span>
							<span class="text" v-else>{{ selectedContractType.length }} {{ $t('filters.contract_type_selected') }}</span>

							<div class="icon-filter" v-if="!selectedContractType.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedContractType')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchContractsType" :placeholder="$t('filters.search_contract_type')">
							</div>

							<template v-for="contract in filteredContractsType">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedContractType', contract.id)" v-bind:class="{active: selectedContractType.includes(contract.id)}" :key="'filter-contract-' + contract.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ contract.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedContractPeriodType.length">{{ $t('filters.by_contract_period_type') }}</span>
							<span class="text" v-else-if="selectedContractPeriodType.length == 1">{{ filterContractPriodType.find(el => el.id == selectedContractPeriodType[0]).name }}</span>
							<span class="text" v-else>{{ selectedContractPeriodType.length }} {{ $t('filters.contract_period_type_selected') }}</span>

							<div class="icon-filter" v-if="!selectedContractPeriodType.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedContractPeriodType')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchContractPriodType" :placeholder="$t('filters.search_contract_period_type')">
							</div>

							<template v-for="contract in filteredContractPriodType">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedContractPeriodType', contract.id)" v-bind:class="{active: selectedContractPeriodType.includes(contract.id)}" :key="'filter-contract-period-' + contract.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ contract.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown last-but-one">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedHoursPerDay.length">{{ $t('filters.by_hours_per_day') }}</span>
							<span class="text" v-else-if="selectedHoursPerDay.length == 1">{{ filterHoursPerDays.find(el => el.id == selectedHoursPerDay[0]).name }}</span>
							<span class="text" v-else>{{ selectedHoursPerDay.length }} {{ $t('filters.hours_per_day') }}</span>

							<div class="icon-filter" v-if="!selectedHoursPerDay.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedHoursPerDay')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchHoursPerDay" :placeholder="$t('filters.search_hours_per_day')">
							</div>

							<template v-for="contract in filteredHoursPerDays">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedHoursPerDay', contract.id)" v-bind:class="{active: selectedHoursPerDay.includes(contract.id)}" :key="'filter-hours-' + contract.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ contract.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown last">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedWorkConditionTypes.length">{{ $t('filters.by_work_condition_types') }}</span>
							<span class="text" v-else-if="selectedWorkConditionTypes.length == 1">{{ filterWorkConditionTypes.find(el => el.id == selectedWorkConditionTypes[0]).name }}</span>
							<span class="text" v-else>{{ selectedWorkConditionTypes.length }} {{ $t('filters.work_condition_types') }}</span>

							<div class="icon-filter" v-if="!selectedWorkConditionTypes.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedWorkConditionTypes')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchWorkConditionTypes" :placeholder="$t('filters.search_work_condition_types')">
							</div>

							<template v-for="contract in filteredWorkConditionTypes">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedWorkConditionTypes', contract.id)" v-bind:class="{active: selectedWorkConditionTypes.includes(contract.id)}" :key="'filter-work-conditions-' + contract.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ contract.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown third-row">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedContractStatus.length">{{ $t('filters.by_contract_status') }}</span>
							<span class="text" v-else-if="selectedContractStatus.length == 1">{{ filterContractStatus.find(el => el.id == selectedContractStatus[0]).name }}</span>
							<span class="text" v-else>{{ selectedContractStatus.length }} {{ $t('filters.contract_status') }}</span>

							<div class="icon-filter" v-if="!selectedContractStatus.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedContractStatus')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchContractStatus" :placeholder="$t('filters.search_contract_status')">
							</div>

							<template v-for="contract in filteredContractStatus">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedContractStatus', contract.status)" v-bind:class="{active: selectedContractStatus.includes(contract.status)}" :key="'filter-contract-status-' + contract.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ contract.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					
					<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
						<icon-trash v-if="$resize && $mq.above(1121)"/>
						<div v-else-if="$resize && $mq.below(1120)">{{$t('filters.clear_all')}}</div>
					</button>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
		<loader-header-list v-else/>

		<template v-if="loadedList">
			<div class="data-list list-goals" v-if="contracts.length">
				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter column-user" v-if="$resize && $mq.above(501)"></div>
						<div class="column-filter sortable column-name contract" @click="sortList('first_name')" v-bind:class="[sortBy == 'first_name' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t('general.name') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div v-if="$resize && $mq.above(1190)" class="column-filter column-roles">
							<div class="text">{{ $t('index-users.roles') }}</div>
						</div>
						<div v-if="$resize && $mq.above(1020)" class="column-filter column-departments">
							<div class="text">{{ $t('index-users.departments') }}</div>
						</div>
						<div v-if="$resize && $mq.above(1326)" class="column-filter column-id">
							<div class="text">{{ $t('salary.contracts.id') }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-type-contract">
							<div class="text">{{ $t('salary.contracts.type') }}</div>
						</div>
						<div v-if="$resize && $mq.above(560)" class="column-filter sortable column-number-contract" @click="sortList('contract_number')" v-bind:class="[sortBy == 'contract_number' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t('salary.contracts.number') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
                        <div v-if="$resize && $mq.above(870)" class="column-filter sortable column-date-contract" @click="sortList('contract_start_date')" v-bind:class="[sortBy == 'contract_start_date' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t('salary.contracts.date') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-for="contract in contracts">
					<div class="row-space-tbf row-list-item" :key="contract.id">
						<div class="space-left"><icon-contract v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
							<div class="column-avatar column-user" :class="[{hide: $resize && !$mq.above(501)}]">
								<img :src="contract.avatar" v-if="contract.avatar">
								<div class="user-circle" v-else>
									<icon-user />
								</div>
							</div>
							<div class="column-name-desc column-name contract link" @click="showContract(contract.id)">
								<div class="circle-status" :class="{active: contract.status === 'active'}"></div>
								<div class="name">
									<div class="name">{{ contract.first_name + ' ' + contract.last_name }}</div>
									<div v-if="contract.marca" class="description">{{$t('salary.contract-data.mark') + ': ' + contract.marca}}</div>
								</div>
							</div>
							<div v-if="$resize && $mq.above(1190)" class="column-simple-text column-roles">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
									<div class="text roles">
										<span class="first" v-if="contract.roles.length == 1">{{ contract.roles[0].name }}</span>
										<span v-else-if="contract.roles.length > 1">{{`${contract.roles[0].name} (+${(contract.roles.length - 1)})`}}</span>
									</div>
									<template slot="popover">
										<div class="simple-text"><span v-for="(role, index) in contract.roles">{{index < contract.roles.length - 1 ? role.name + ', ' : role.name}}</span></div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && $mq.above(1020)" class="column-simple-text column-departments">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
									<div class="text departments">
										<span class="first" v-if="contract.departments.length == 1">{{ contract.departments[0].name }}</span>
										<span v-else-if="contract.departments.length > 1">{{`${contract.departments[0].name} (+${(contract.departments.length - 1)})`}}</span>
									</div>
									<template slot="popover">
										<div class="simple-text"><span v-for="(department, index) in contract.departments">{{index < contract.departments.length - 1 ? department.name + ', ' : department.name}}</span></div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && $mq.above(1326)" class="column-simple-text column-id">
								<div class="text">{{contract.cnp}}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-simple-text column-type-contract">
								<div class="text">{{contract.contract_type}}</div>
							</div>
                            <div v-if="$resize && $mq.above(560)" class="column-simple-text column-number-contract">
								<div class="text">{{contract.contract_number}}</div>
							</div>
                            
							<div v-if="$resize && $mq.above(870)" class="column-simple-text column-date-contract">
								<div class="text">{{contract.contract_date | moment('DD MMM YYYY')}}</div>
								<div class="text text_center"><small>{{contract.contract_end_date ? $t('salary.contracts.end_in') : ''}}</small></div>
								<div class="text end_in"><small>{{contract.contract_end_date | moment('DD MMM YYYY')}}</small></div>
							</div>
							<div class="column-edit-mobile" v-if="$resize && $mq.below(500)">
								<div class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef'+contract.id" :id="'dropdownEdit'+contract.id" data-toggle="dropdown">
										<icon-edit-mobile />
									</div>
									<div style="min-width: 150px" class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ contract.id " :aria-labelledby="'dropdownEdit'+contract.id">
										<div class="dropdown-item" @click="showContract(contract.id)"><div class="simple-text">{{$t('general.view')}}</div></div>
										<div class="dropdown-item" @click="showModal('statements', {type: 'statements', from: 'index_contracts-statements', model: contract})"><div class="simple-text">{{$t('general.statements')}}</div></div>
										<div class="dropdown-item" @click="showModal('situation', {type: 'income_situation', from: 'index_contracts_income_situation', text: $t('salary.download.income_situation_title'), model: contract})"><div class="simple-text">{{$t('salary.download.income_situation')}}</div></div>
										<div class="dropdown-item" @click="showModal('situation', {type: 'co_situation', from: 'index_contracts_co_situation', text: $t('salary.download.co_situation_title'), model: contract})"><div class="simple-text">{{$t('salary.download.co_situation')}}</div></div>
										<div class="dropdown-item" @click="showModal('situation', {type: 'cm_situation', from: 'index_contracts_cm_situation', text: $t('salary.download.cm_situation_title'), model: contract})"><div class="simple-text">{{$t('salary.download.cm_situation')}}</div></div>
										<div class="dropdown-item" @click="showModal('delete', {type: 'contract', from: 'index_contracts', model: contract})"><div class="simple-text">{{$t('general.delete')}}</div></div>
									</div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div v-if="$resize && $mq.above(501)" class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef'+contract.id" :id="'dropdownEdit'+contract.id" data-toggle="dropdown">
									<icon-edit />
								</div>
								<div style="min-width: 150px" class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ contract.id " :aria-labelledby="'dropdownEdit'+contract.id">
									<div class="dropdown-item" @click="showContract(contract.id)"><div class="simple-text">{{$t('general.view')}}</div></div>
									<div class="dropdown-item" @click="showModal('statements', {type: 'statements', from: 'index_contracts-statements', model: contract})"><div class="simple-text">{{$t('general.statements')}}</div></div>
									<div class="dropdown-item" @click="showModal('situation', {type: 'income_situation', from: 'index_contracts_income_situation', text: $t('salary.download.income_situation_title'), model: contract})"><div class="simple-text">{{$t('salary.download.income_situation')}}</div></div>
									<div class="dropdown-item" @click="showModal('situation', {type: 'co_situation', from: 'index_contracts_co_situation', text: $t('salary.download.co_situation_title'), model: contract})"><div class="simple-text">{{$t('salary.download.co_situation')}}</div></div>
									<div class="dropdown-item" @click="showModal('situation', {type: 'cm_situation', from: 'index_contracts_cm_situation', text: $t('salary.download.cm_situation_title'), model: contract})"><div class="simple-text">{{$t('salary.download.cm_situation')}}</div></div>
									<div class="dropdown-item" @click="showModal('delete', {type: 'contract', from: 'index_contracts', model: contract})"><div class="simple-text">{{$t('general.delete')}}</div></div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<div class="icon-empty">
						<img src="/build/images/no-results-found.svg">
					</div>
					<div class="title">{{ $t('empty.title')}}</div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list v-else/>
		
		<infinite-loading :identifier="infiniteId" @infinite="infiniteContractsHandler" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</template>

<script>
 	import IconContract from '../../../Icons/Contract'
	import IconDownload from '../../../Icons/Download'
	import IconPdf from '../../../Icons/Pdf'
	import IconXls from '../../../Icons/Xls'
    import IconSearch from '../../../Icons/Search'
    import IconPlus from '../../../Icons/Plus'
    import IconArrow from '../../../Icons/Arrow'
    import IconUser from '../../../Icons/User'
    import IconEdit from '../../../Icons/EditDots'
	import IconEditMobile from '../../../Icons/Edit'
	import IconTrash from '../../../Icons/Trash'
	import IconSettings from '../../../Icons/Settings'
	import IconClose from '../../../Icons/Close'
    import LoaderItemsList from '../../../PagesLoaders/ItemsList'
    import LoaderHeaderList from '../../../PagesLoaders/HeaderList'
	import InfiniteLoading from 'vue-infinite-loading'

    export default {
    	components: {
			IconContract,
			IconDownload,
			IconPdf,
			IconXls,
            IconSearch,
            IconPlus,
            IconArrow,
            IconUser,
            IconEdit,
			IconEditMobile,
			IconTrash,
			IconSettings,
			IconClose,
           	LoaderItemsList, 
			LoaderHeaderList,
			InfiniteLoading
        },
        data() {
            return {
            	loaded: false,
				loadedList: false,
            	search_filter: '',
            	contracts: [],
            	sortBy: '',
            	sortDirection: 'asc',
				page: 0,
				infiniteId: 1,
				queryFiltersCount: 0,
            	extendFilters: false,

				filterUsers: [],
            	selectedUsers: [],
            	searchUsers: '',

				filterRoles: [],
            	selectedRoles: [],
            	searchRoles: '',

				filterDepartments: [],
            	selectedDepartments: [],
            	searchDepartments: '',

				filterWorkstations: [],
            	selectedWorkstations: [],
            	searchWorkstations: '',

				filterContractsType: [],
            	selectedContractType: [],
            	searchContractsType: '',

				filterContractPriodType: [],
            	selectedContractPeriodType: [],
            	searchContractPriodType: '',

				filterHoursPerDays: [],
				selectedHoursPerDay: [],
				searchHoursPerDay: '',

				filterWorkConditionTypes: [],
				selectedWorkConditionTypes: [],
				searchWorkConditionTypes: '',

				filterContractStatus: [{id:1, name:'Toate (active + inactive)', status:true}],
				selectedContractStatus: [],
				searchContractStatus: ''
            }
        },
        async mounted(){
			this.$refs.infiniteLoading.status = 1
			this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)

			await this.getFilters()
        	await this.checkQueryFilters()

			setTimeout(() => {
				var title = this.$t('contracts-navbar.contracts');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshContractsIndex', () => {
				this.getFilters()
				this.checkQueryFilters()
				this.searchFilterFunct()
			})
        },
        watch: {
        	search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
        },
		computed: {
			filteredUsers(){
				return getByKeywordFilter(this.filterUsers, this.searchUsers)
			},
			filteredDepartments(){
				return getByKeywordFilter(this.filterDepartments, this.searchDepartments)
			},
			filteredWorkstations(){
				return getByKeywordFilter(this.filterWorkstations, this.searchWorkstations)
			},
			filteredRoles(){
				return getByKeywordFilter(this.filterRoles, this.searchRoles)
			},
			filteredContractsType(){
				return getByKeywordFilter(this.filterContractsType, this.searchContractsType)
			},
			filteredContractPriodType(){
				return getByKeywordFilter(this.filterContractPriodType, this.searchContractPriodType)
			},
			filteredHoursPerDays() {
				return getByKeywordFilter(this.filterHoursPerDays, this.searchHoursPerDay)
			},
			filteredWorkConditionTypes() {
				return getByKeywordFilter(this.filterWorkConditionTypes, this.searchWorkConditionTypes)
			},
			filteredContractStatus() {
				return getByKeywordFilter(this.filterContractStatus, this.searchContractStatus)
			}
        },
        
        methods: {
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {users: true, departments: true, workstations: true, roles: true, contract_types: true, contract_period_types: true, hours_per_day: true, work_condition_types: true}})
				.then(({data}) => {
					this.filterUsers = data.data.users
					this.filterRoles = data.data.roles
					this.filterDepartments = data.data.departments
					this.filterWorkstations = data.data.workstations
					this.filterContractsType = data.data.contract_types
					this.filterContractPriodType = data.data.contract_period_types
					this.filterHoursPerDays = data.data.hours_per_day.map(el => {return {
						id: el.hours_per_day, name: el.hours_per_day.toString()
					}})
					this.filterWorkConditionTypes = data.data.work_condition_types
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
			infiniteContractsHandler($state){
				var paramsCall = { view_more: this.page * 20}
        		this.queryObject(paramsCall)

				if(this.sortBy){
        			paramsCall.sort_by = this.sortBy
        			paramsCall.sort_direction = this.sortDirection
        		}

        		axios.get(this.$auth.user().instance.id + '/contracts', {params: paramsCall})
        		.then(({data}) => {
        			if(data.data.length){
        				this.page++
        				this.contracts.push(...data.data)
						setTimeout(() => { $state.loaded() }, 200)
							if(data.data.length < 20){
								$state.complete()
							}
					}else{
						$state.complete()
					}
					this.loadedList = true

        		}).catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		}).finally(() => { 
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
        	},
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }

					if(key == 'users'){ paramsCall.user_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'departments'){ paramsCall.department_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'workstations'){ paramsCall.workstation_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'roles'){ paramsCall.role_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'contract_type_id'){ paramsCall.contract_type_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'contract_period_type_id'){ paramsCall.contract_type_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'hours_per_days'){ paramsCall.hours_per_days = dataQuery[key].split(',').map(Number) }
					if(key == 'work_condition_type_id'){ paramsCall.work_condition_type_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'all'){ paramsCall.all = dataQuery[key] }
				});
			},
			selectOptionFilter(filter, value) {
				if(typeof value == 'string' || typeof value == 'boolean'){
					if(this[filter].includes(value)){
						this[filter].splice(this[filter].indexOf(value), 1)
					}else{
						this[filter].push(value)
					}
					
				} else {
					if(this[filter].includes(parseInt(value))){
					
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
					}else{
						this[filter].push(parseInt(value))
					}
					
				}

				this.addFilterToRouteQuery(filter)
        	},
			addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);
				
				if(filterName == 'selectedUsers' || filterName == 'all'){ dataQuery['users'] = this.selectedUsers.toString(); }
				if(filterName == 'selectedWorkstations' || filterName == 'all'){ dataQuery['workstations'] = this.selectedWorkstations.toString(); }
				if(filterName == 'selectedDepartments' || filterName == 'all'){ dataQuery['departments'] = this.selectedDepartments.toString(); }
				if(filterName == 'selectedRoles' || filterName == 'all'){ dataQuery['roles'] = this.selectedRoles.toString(); }
				if(filterName == 'selectedContractType' || filterName == 'all'){ dataQuery['contract_type_id'] = this.selectedContractType.toString(); }
				if(filterName == 'selectedContractPeriodType' || filterName == 'all'){ dataQuery['contract_period_type_id'] = this.selectedContractPeriodType.toString(); }
				if(filterName == 'selectedHoursPerDay' || filterName == 'all'){ dataQuery['hours_per_days'] = this.selectedHoursPerDay.toString(); }
				if(filterName == 'selectedWorkConditionTypes' || filterName == 'all'){ dataQuery['work_condition_type_id'] = this.selectedWorkConditionTypes.toString(); }
				if(filterName == 'selectedContractStatus' || filterName == 'all'){ dataQuery['all'] = this.selectedContractStatus.toString(); }

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length

				this.$router.push({query : dataQuery });
				this.searchFilterFunct()
			},
			clearFilter(filter){
				if(filter == 'all'){
					this.selectedUsers = []
					this.selectedContractType = []
					this.selectedWorkstations = []
					this.selectedDepartments = []
					this.selectedRoles = []
					this.selectedContractPeriodType = []
					this.selectedHoursPerDay = []
					this.selectedWorkConditionTypes = []
					this.selectedContractStatus = []
				}else{
					this[filter] = []
				}

				this.addFilterToRouteQuery(filter)
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }

					if(key == 'users'){ this.selectedUsers = dataQuery[key].split(',').map(Number) }
					if(key == 'workstations'){ this.selectedWorkstations = dataQuery[key].split(',').map(Number) }
					if(key == 'departments'){ this.selectedDepartments = dataQuery[key].split(',').map(Number) }
					if(key == 'roles'){ this.selectedRoles = dataQuery[key].split(',').map(Number) }
					if(key == 'contract_type_id'){ this.selectedContractType = dataQuery[key].split(',').map(Number) }
					if(key == 'contract_period_type_id'){ this.selectedContractPeriodType = dataQuery[key].split(',').map(Number) }
					if(key == 'hours_per_days'){ this.selectedHoursPerDay = dataQuery[key].split(',').map(Number) }
					if(key == 'work_condition_type_id'){ this.selectedWorkConditionTypes = dataQuery[key].split(',').map(Number) }
					if(key == 'all'){ this.selectedContractStatus = dataQuery[key].split(',').map(Boolean) }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length
			},
			sortList(column){
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;

				this.searchFilterFunct()
			},
			showContract(id){
				this.$router.push({ name: 'contract-show', params: {id: id} })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.contracts = []
				this.infiniteId += 1
			},
			async donwloadContractRaportPdf(status){
				var paramsCall = {active: status}
				await this.queryObject(paramsCall)

				return axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/contracts?inline=true`,
				{	
					params: paramsCall,
				})
				.then((response) => {
					this.$root.$emit('open_modal', 'pdf', {data: response.data, from: 'contracts', filters: paramsCall, date: {}, text: status == null ? this.$t('salary.download.contracts_null') : status == true ? this.$t('salary.download.contracts_inactive') : this.$t('salary.download.contracts_closed')}, false);
				})
			}, 
			async donwloadContractRaportXls(status){
				var paramsCall = {active: status}
				await this.queryObject(paramsCall)

				return axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/xls/contracts`, 
				{
					params: paramsCall,
                	responseType: 'blob'
				})
				.then((response) => {
					let blob = new Blob([response.data], { type:"application/octet-stream" })
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = this.$t('salary.download.clocking') + '.xlsx';

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
			},
			async donwloadInsurersPdf(status){
				var paramsCall = {is_dependent: status}
				await this.queryObject(paramsCall)

				return axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/coinsureds?inline=true`,
				{	
					params: paramsCall,
				})
				.then((response) => {
					this.$root.$emit('open_modal', 'pdf', {data: response.data, from: 'coinsureds', filters: paramsCall, date: {}, text: status ? this.$t('salary.download.insured') : this.$t('salary.download.coinsured')}, false);
				})
			},
			async donwloadInsurersXls(status){
				var paramsCall = {is_dependent: status}
				await this.queryObject(paramsCall)

				return axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/xls/coinsureds`, 
				{
					params: paramsCall,
                	responseType: 'blob'
				})
				.then((response) => {
					let blob = new Blob([response.data], { type:"application/octet-stream" })
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = this.$t('salary.download.clocking') + '.xlsx';

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
			}
        }
    }
	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
	.list-goals{
		.row-list-item{
			&:hover{
				.content{
					.column-name{
						.name{
							font-weight: 700;
						}
					}
				}
			}
		}
		.column-roles{
			flex: 0 0 180px;
			.roles{
				display: flex;
				padding-right: 15px;
				.word{
					padding-right: 10px;
				}
				.number{
					padding-right: 10px;
					text-align: center;
					min-width: 57px;
					line-height: 16px;
					padding-bottom: 3px;
					margin: auto;
				}
			}
		}
		.circle-status{
			height: 10px;
			width: 10px;
			min-width: 10px;
			border-radius: 50%;
			background: #c5c5c5;
			border: 1px solid #747683;
			margin-right: 7px;
			margin-top: 3px;
			&.active {
				background: #6ce0a6;
				border: 1px solid #03ae58;
			}
		} 
		.column-departments{
			flex: 0 0 180px;
			.departments{
				display: flex;
				padding-right: 15px;
				.word{
					padding-right: 10px;
				}
				.number{
					padding-right: 10px;
					text-align: center;
					min-width: 57px;
					line-height: 16px;
					padding-bottom: 3px;
					margin: auto;
				}
			}
		}
		.column-name{
			flex: 1 1 auto;
			&.link{
				cursor: pointer;
			}
			&.contract {
				display: flex;
			}
		}
		.column-id{
			flex: 0 0 130px;
		}
        .column-type-contract{
			flex: 0 0 160px;
		}
        .column-number-contract{
			flex: 0 0 100px;
		}
        .column-date-contract{
			flex: 0 0 90px;
		}
		.column-user{
			flex: 0 0 60px;
			display: flex;
			justify-content: flex-start;
		}
		.column-roles{
			flex: 0 0 180px;
			.roles{
				display: flex;
				padding-right: 15px;
				.word{
					padding-right: 10px;
				}
				.number{
					padding-right: 10px;
					text-align: center;
					min-width: 57px;
					line-height: 16px;
					padding-bottom: 3px;
					margin: auto;
				}
			}
		}
		.column-departments{
			flex: 0 0 180px;
			.departments{
				display: flex;
				padding-right: 15px;
				.word{
					padding-right: 10px;
				}
				.number{
					padding-right: 10px;
					text-align: center;
					min-width: 57px;
					line-height: 16px;
					padding-bottom: 3px;
					margin: auto;
				}
			}
		}
	}
	.dropdown-item {
		&.disabled{
			color: #AFB1B8 !important;
		}
	}
	.filter-btn-mobile{
		.icon{
			svg{
			fill: black;
		}
		}
		
	}
	.text{
		&.text_center{
			text-align: center;
			color: #606270 !important;
		}
		&.end_in{
			color: #ff4550 !important;
			text-align: center;
		}
	}
	
</style>